import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/account/bots": [5,[2]],
		"/account/bots/new": [7,[2]],
		"/account/bots/[uuid]/edit": [6,[2]],
		"/account/password": [8,[2]],
		"/account/profile": [9,[2]],
		"/audio": [10],
		"/login": [11],
		"/register": [12],
		"/rooms": [13,[3]],
		"/rooms/chat/[uuid]": [14,[3]],
		"/rooms/chat/[uuid]/invite": [15,[3]],
		"/rooms/new": [16,[3]],
		"/success": [17]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';